.attendance-group {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.attendance-title {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.attendance-count {
    text-align: center;
    color: #666;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.attendee-name {
    flex-grow: 1;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    text-align: center;
    align-self: center;
}

.attendance-group ul {
    padding: 0;
    margin: 0;
}

.attendance-group li {
    list-style: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
}

.attendance-group li:last-child {
    border-bottom: none;
}

.attendance-buttons {
    display: flex;
}

.attendance-yes {
    border-color: #28a745;
}

.attendance-maybe {
    border-color: #ffc107;
}

.attendance-no {
    border-color: #dc3545;
}
