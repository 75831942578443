.login-form{
    max-width: 600px;
    min-width: 350px;
    max-height: 700px;
    width: 50%;
    height: 80%;
    margin: 100px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
    padding-bottom: 50px;
}

.login-row{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    max-width: 100%;
}

.login-row input{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border: none;
    font-size: 1.3rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
    transition: box-shadow 0.2s ease-in;
}

.login-row input:focus{
    box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
    outline: none;
}

.login-row input::-webkit-input-placeholder{
    max-width: 90%;
    opacity: 1;
    transition: opacity 0.25s ease-out;
}

.login-row input:hover::-webkit-input-placeholder,
.login-row input:focus::-webkit-input-placeholder{
    opacity: 50%;
}

.login-row label{
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    color: #6ea7d3;
}

.login-row button{
    border-radius: 25px;
    width: 50%;
    max-width: 200px;
    /*height: 40px;*/
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
/*    background: rgb(34,193,195);*/
    border: 0;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
}

.login-row button:hover{
    opacity: 0.8;
}