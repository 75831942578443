.drag-and-drop-container {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.name-list {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    padding: 10px;
    border: 2px dashed #4caf50;
    width: 300px;
    text-align: center;
    flex-wrap: wrap;
}

.name {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
}

.spielfeld {
    width: 300px;
    height: 300px;
    border: 2px dashed #333;
    position: relative; /* Damit die dropped-name-Elemente relativ dazu positioniert werden */
}

.dropped-name {
    padding: 5px;
    background-color: #ffeb3b;
    margin: 5px 0;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    position: absolute; /* Ermöglicht absolute Positionierung basierend auf x und y */
}
