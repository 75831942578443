.create-training-button {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-training-button .plus-icon {
    font-size: 24px;
    margin-bottom: 4px;
}

.create-training-button .button-text {
    font-size: 16px;
    text-align: center;
}