.language-switcher {
    display: flex;
}

.language-switcher button {
    background: transparent;
    border: none;
    color: inherit;
    font-size: 1rem;
    cursor: pointer;
}

.language-switcher button:hover {
    text-decoration: underline;
}
