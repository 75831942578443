.card {
    border-radius: 12px;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.card-header {
    font-weight: 600;
    background-color: #f9f9f9;
    border-bottom: none;
}

.card-body {
    transition: background-color 0.3s ease, border 0.3s ease;
}

.card-body p {
    font-weight: 500;
    color: #666;
}
