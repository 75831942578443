.attendance-summary-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
}

.attendance-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9em;
}

.attendance-icon {
    font-size: 1.5em;
    margin-bottom: 5px;
    display: inline-block;
}

.thumbs-up {
    color: #28a745;
}

.thumbs-down {
    color: #dc3545;
}

.question {
    color: #ffc107;
}
