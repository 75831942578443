.attendance-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.attendance-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50% !important;
    border: 1px solid black !important;
    font-size: 1.25rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
    color: #fff;
    background-color: #d9d9d9;
    cursor: pointer;
}

.attendance-btn.yes {
    background-color: #28a745;
}

.attendance-btn.yes.active {
    background-color: #218838;
}

.attendance-btn.maybe {
    background-color: #ffc107;
}

.attendance-btn.maybe.active {
    background-color: #e0a800;
}

.attendance-btn.no {
    background-color: #dc3545;
}

.attendance-btn.no.active {
    background-color: #c82333;
}

.attendance-btn:hover:not(.active) {
    background-color: #b3b3b3;
}
