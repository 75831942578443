.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1;
    padding-top: 80px;
}

.footer-nav {
    padding-top: 10px !important;
    margin-top: auto;
}


.header-logo {
    margin-right: 30px;
    height: 68px;
}

@media (prefers-reduced-motion: no-preference) {
    .header-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.club-title {
    font-size: 35px;
    display: block;
}

.club-subtitle {
    font-size: 55px;
    font-weight: bold;
    display: block;
    line-height: 1;
}

.club-subtitle-1 {
    font-size: 25px;
    color: #888;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.amountButton {
    min-width: 70px;
    white-space: nowrap;
}

.payButton {
    min-width: 90px;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
    opacity: .25;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}

.deleteButton {
    position: absolute;
    right: 0;
}

.subtitle {
    position: relative;
    font-size: 10px;
    color: #888;
    display: block;
}
