.container {
    max-width: 1200px;
    margin: 0 auto;
}

.not-assigned-section h4 {
    font-weight: 600;
    color: #6c757d; /* Sekundärfarbe */
}

.card-title {
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.group-player-name {
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.settings-section .form-label {
    font-weight: bold;
}

button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
}

button .fa-icon {
    margin-right: 0.5rem;
}
