body {
	/*background-image: linear-gradient(black, black), url("../images/sportheim.jpeg");*/
	/*height: 100vh;*/
	width: 100%;
	background-size: cover;
	background-attachment: fixed;
	/*background-blend-mode: saturation;*/
	margin: auto;
}

.header-content {
	padding-top: 75px;
	margin: auto;
	width: 75%;
}

.header-card-image {
	width: 100%;
}

.playerSearchInput {
	margin-top: 20px;
	max-width: 400px;
}

.profileCardImage {
	width: 96px !important;
	height: 96px !important;
	border: 1px #000 solid;
	border-radius: 48px !important;
	padding: 9px;
	margin: 20px auto auto;
}

.punishmentModal {
	max-height: 60vh;
}