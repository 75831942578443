.col-item {
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.col-item:hover {
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-outline-danger {
    transition: background-color 0.2s ease, color 0.2s ease;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    color: #fff;
}
